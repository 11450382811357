import React from "react";

import "./styles/ProjectSlider.scss";

class ProjectSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="slider">
          <div
            className="slide1"
            style={{ backgroundImage: `url(${this.props.images.slide1Img})` }}
          ></div>
          <div
            className="slide2"
            style={{ backgroundImage: `url(${this.props.images.slide2Img})` }}
          ></div>
          <div
            className="slide3"
            style={{ backgroundImage: `url(${this.props.images.slide3Img})` }}
          ></div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProjectSlider;
