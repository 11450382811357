import React, { useEffect } from "react";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-blue.css";
import ProjectSlider from "../../components/ProjectSlider";

import "../styles/Project.scss";
import slide1Img from "../../assets/img/projects/mindvoice/1.png";
import slide2Img from "../../assets/img/projects/mindvoice/2.png";
import slide3Img from "../../assets/img/projects/mindvoice/3.png";
import githubImg from "../../assets/img/projects/github.png";

export default function MindVoice() {
  const sliderImages = {
    slide1Img,
    slide2Img,
    slide3Img,
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <div className="container-fluid project-header">
        <div className="container d-flex position-relative">
          <ProjectSlider images={sliderImages} />
          <div className="ps-md-3">
            <div className="d-flex h-100 flex-column">
              <h1>Mind Voice</h1>
              <p>
                Aplicación gratuita desarrollada para ayudar a niños/as que se
                encuentran aprendiendo a leer y escribir y/o sufren problemas
                a la hora de comunicarse con el entorno. Todo esto mediante el
                uso de imágenes, texto, asistencias de voz y herramientas de
                accesibilidad.
              </p>
              <a href="https://andez.dev/mindvoice" target="_blank" rel="noreferrer">
                <AwesomeButton type="secondary" className="btn-download">
                  <i className="fas fa-sign-in-alt me-2"></i> Acceder
                </AwesomeButton>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid project">
        <div className="container">
          <h2>Funcionamiento básico</h2>
          <hr className="separator-red" />
          <div className="project-info">
            <p>
              La aplicación se desarrolló pensada para menores de 10 años,
              esto hace que uno de los focos principales a la hora de
              diseñarla sea conseguir la mayor facilidad en su uso.
              <br />
              <br />
              En resumen, Mind Voice consta de un campo de texto en el que el
              usuario puede escribir libremente lo que desee decir, ya sea de
              forma manual o mediante la ayuda de pictogramas. Una vez
              ingresado, y al presionar una tecla, la aplicación reproducirá
              lo que el usuario haya escrito con un asistente de voz.
              <br />
              <br />
              Al ingresar a la aplicación, esta nos permitirá seleccionar la
              voz (hombre - mujer) e "interfaz" (teclado completo - teclado
              adaptado) que utilizaremos. Esto mediante las teclas
              correspondientes que se muestran en pantalla.
              <br />
              <br />
              Las interfaces disponibles son:
            </p>
            <ul className="list-unstyled">
              <li>
                <b>Teclado completo:</b>
                <br />
                Pensada para ser utilizada fácilmente con un teclado normal de
                computadora en su totalidad.
              </li>
              <li>
                <b>Teclado adaptado:</b>
                <br />
                Pensada para utilizarse con la menor cantidad de teclas
                posibles, ayudando a personas con dificultades motrices.
              </li>
            </ul>
          </div>

          <h2>Herramientas</h2>
          <hr className="separator-red" />
          <div className="project-info">
          <p>La aplicación consta, principalmente, de:</p>
            <ul className="list-unstyled">
              <li>
                <b>Campo de texto:</b>
                <br />
                En este campo el usuario escribirá lo que quiera decir.
                Dependiendo de la interfaz elegida, este tendrá más o menos
                opciones.
              </li>
              <li>
                <b>Pictogramas:</b>
                <br />
                Mediante estas imágenes, el usuario podrá construir frases o
                simplemente escribir palabras que aún no conozca con una sola
                tecla (útil para niños/as que no recuerden como se escriben
                ciertas palabras).
              </li>
              <li>
                <b>Asistente de voz:</b>
                <br />
                Voz artificial que dirá en voz alta lo que el usuario escribió
                en el campo de texto, todo con tan solo presionar un botón.
              </li>
            </ul>
          </div>

          <h2>Colaboración</h2>
          <hr className="separator-red" />
          <div className="project-info text-center">
            <p className="font-22">
              ¿Te gustaría ser parte del desarrollo? ¡Accede al proyecto en
              GitHub!
            </p>
            <a
              href="https://github.com/symphonic15/mindvoice"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={githubImg} className="mw-100" alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}