import React from "react";

import "./Footer.scss";
import logo from "../../assets/img/logo.png";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer-space"></div>
      <div className="footer">
        <img src={logo} alt="Logo" />
      </div>
    </React.Fragment>
  );
}

export default Footer;
