import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Home.scss";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-blue.css";

import drawnProfilePhoto from "../../assets/img/index-header-profile.png";
import investmentTracker from "../../assets/img/projects/investment-tracker/main.png";
import mindVoice from "../../assets/img/projects/mindvoice/main.png";
import cvGenerator from "../../assets/img/projects/cv-generator/main.png";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="container-fluid index-header">
        <div className="container">
          <img
            src={drawnProfilePhoto}
            alt="Profile"
            className="index-header-profile-img"
          />
          <h3 className="index-header-profile-name">Andrés Sánchez</h3>
          <p className="index-header-summary">
            Desarrollador Front-end y colaborador activo en proyectos de código
            libre.
          </p>
          <a
            href="https://github.com/symphonic15"
            target="_blank"
            rel="noreferrer"
          >
            <button className="index-header-btn">
              <i className="fab fa-github"></i> GitHub
            </button>
          </a>
        </div>
      </div>
      <div className="container-fluid index-projects">
        <div className="container">
          <h2>Portfolio</h2>
          <hr className="separator-red" />
          <div className="row mb-4">
            <div className="col-xl-5 col-lg-6 index-project-img">
              <img src={investmentTracker} alt="" />
            </div>
            <div className="col-xl-7 col-lg-6 index-project-info">
              <h2>Investment Tracker</h2>
              <p>
                Gestor de inversiones en stocks y criptomonedas, con
                categorizaciones y datos tales como ganancias/pérdidas actuales e
                históricas, últimas operaciones de compra/venta y valor total
                invertido, entre otros.
              </p>
              <Link to="/projects/investment-tracker">
                <AwesomeButton type="primary" className="btn-more-info">
                  Conocer más
                </AwesomeButton>
              </Link>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-5 col-lg-6 index-project-img">
              <img src={mindVoice} alt="" />
            </div>
            <div className="col-xl-7 col-lg-6 index-project-info">
              <h2>Mind Voice</h2>
              <p>
                Aplicación gratuita desarrollada para ayudar a niños/as que se
                encuentran aprendiendo a leer y escribir y/o sufren problemas a
                la hora de comunicarse con el entorno. Todo esto mediante el uso
                de imágenes, texto, asistencias de voz y herramientas de
                accesibilidad.
              </p>
              <Link to="/projects/mindvoice">
                <AwesomeButton type="primary" className="btn-more-info">
                  Conocer más
                </AwesomeButton>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5 col-lg-6 index-project-img">
              <img src={cvGenerator} alt="" />
            </div>
            <div className="col-xl-7 col-lg-6 index-project-info">
              <h2>CV Generator</h2>
              <p>
                Generador de Curriculum Vitae con selección de plantillas y
                vista previa en tiempo real.
              </p>
              <Link to="/projects/cv-generator">
                <AwesomeButton type="primary" className="btn-more-info">
                  Conocer más
                </AwesomeButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
