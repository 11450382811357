import React, { useEffect } from "react";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-blue.css";
import ProjectSlider from "../../components/ProjectSlider";

import "../styles/Project.scss";
import slide1Img from "../../assets/img/projects/investment-tracker/1.jpg";
import slide2Img from "../../assets/img/projects/investment-tracker/2.jpg";
import slide3Img from "../../assets/img/projects/investment-tracker/3.jpg";
import githubImg from "../../assets/img/projects/github.png";

export default function InvestmentTracker() {
  const sliderImages = {
    slide1Img,
    slide2Img,
    slide3Img,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container-fluid project-header">
        <div className="container d-flex position-relative">
          <ProjectSlider images={sliderImages} />
          <div className="ps-md-3">
            <div className="d-flex h-100 flex-column">
              <h1>Investment Tracker</h1>
              <p>
                Gestor de inversiones en stocks y criptomonedas, con
                categorizaciones y datos tales como ganancias/pérdidas actuales e
                históricas, últimas operaciones de compra/venta y valor total
                invertido, entre otros.
              </p>
              <a href="https://github.com/symphonic15/investment-tracker/releases/download/v1.2.0/InvestmentTrackerSetup1.2.0.rar">
                <AwesomeButton type="secondary" className="btn-download">
                  <i className="fas fa-download me-2"></i> Descargar
                </AwesomeButton>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid project">
        <div className="container">
          <h2>Herramientas y funcionamiento básico</h2>
          <hr className="separator-red" />
          <div className="project-info">
            <p>
              Investment Tracker le permite realizar un seguimiento
              personalizado de su cartera de inversiones, definiendo sus propios
              parámetros y dejando que la aplicación calcule por usted. Esto
              permite no limitarse a los activos y cotizaciones establecidos por
              un sistema, ya que es usted quien define estos.
            </p>
            <p>
              A continuación, se detallan algunas de las caracteristicas
              principales:
            </p>
            <ul className="list-unstyled">
              <li>
                <b>Registro de activos:</b>
                <br />
                Agregue/elimine activos al registro de su cartera simplemente
                ingresando el nombre de cada una y defina la cotización de
                referencia utilizada.
              </li>
              <li>
                <b>Registro de operaciones:</b>
                <br />
                Registre cada una de las operaciones realizadas en sus activos y
                mantenga un seguimiento de estas.
              </li>
              <li>
                <b>Calculo de ganancias/perdidas:</b>
                <br />
                Obtenga el estado actual de una inversión con solo indicar la
                cotización actual del activo.
              </li>
              <li>
                <b>Historial:</b>
                <br />
                Compare históricamente el estado de su cartera en la aplicación.
              </li>
              <li>
                <b>Administración de carteras:</b>
                <br />
                Almacene toda la información registrada de sus carteras en
                archivos e impórtelos a la aplicación desde cualquier lugar.
              </li>
            </ul>
          </div>

          <h2>Primeros pasos</h2>
          <hr className="separator-red" />
          <div className="project-info">
            <ul className="list-unstyled">
              <li>
                <b>Descarga e instalación:</b>
                <br />
                Descargue la última versión desde el siguiente link:{" "}
                <a
                  href="https://github.com/symphonic15/investment-tracker/releases/download/v1.2.0/InvestmentTrackerSetup1.2.0.rar"
                  className="text-red"
                >
                  Investment Tracker 1.2.0
                </a>
                , descomprima el archivo y siga los pasos de instalación.
              </li>
              <li>
                <b>Iniciar</b>
                <br />
                Al iniciar la aplicación, esta lo llevara al panel general.
                Aquí, podrá consultar las ultimas operaciones realizadas en
                todos los activos y la perdida/ganancia total registrada
                historicamente.
              </li>
              <li>
                <b>Registrar activos:</b>
                <br />
                Dirijase a la sección "My investments", en esta sección podra
                agregar/eliminar activos operados a la cartera y consultar
                información relevante sobre el estado actual de esta. Agregue un
                activo operado presionando "Add" e identificandolo por su
                nombre.
              </li>
              <li>
                <b>Registrar operaciones:</b>
                <br />
                Presione el activo agregado para ingresar al panel de
                información de este, en donde podra consultar y agregar las
                ultimas operaciones realizadas, actualizar su cotización de
                referencia y obtener datos de su estado actual en la cartera. En
                la lista de operaciones, agregue una o más con el boton "Add".
              </li>
              <li>
                <b>Actualizar el valor del activo:</b>
                <br />
                En el mismo panel, presione el boton "Update" (a la derecha del
                ultimo precio registrado) y defina la cotización actual del
                activo en el mercado. La aplicación calculará automaticamente la
                ganancia/perdida en base a las operaciones registradas y
                actualizará el valor de la cartera.
              </li>
            </ul>
          </div>

          <h2>Colaboración</h2>
          <hr className="separator-red" />
          <div className="project-info text-center">
            <p className="font-22">
              ¿Te gustaría ser parte del desarrollo? ¡Accede al proyecto en
              GitHub!
            </p>
            <a
              href="https://github.com/symphonic15/investment-tracker"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={githubImg} className="mw-100" alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
