import React, { useEffect } from "react";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-blue.css";
import ProjectSlider from "../../components/ProjectSlider";

import "../styles/Project.scss";
import slide1Img from "../../assets/img/projects/cv-generator/1.png";
import slide2Img from "../../assets/img/projects/cv-generator/2.png";
import slide3Img from "../../assets/img/projects/cv-generator/3.png";
import githubImg from "../../assets/img/projects/github.png";

export default function CVGenerator() {
  const sliderImages = {
    slide1Img,
    slide2Img,
    slide3Img,
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <div className="container-fluid project-header">
        <div className="container d-flex position-relative">
          <ProjectSlider images={sliderImages} />
          <div className="ps-md-3">
            <div className="d-flex h-100 flex-column">
              <h1>CV Generator</h1>
              <p>
                Generador de Curriculum Vitae con selección de plantillas y vista previa en tiempo real.
              </p>
              <a href="https://andez.dev/cv-generator" target="_blank" rel="noreferrer">
                <AwesomeButton type="secondary" className="btn-download">
                  <i className="fas fa-sign-in-alt me-2"></i> Acceder
                </AwesomeButton>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid project">
        <div className="container">
          <h2>Funcionamiento básico</h2>
          <hr className="separator-red" />
          <div className="project-info">
            <ol>
              <li>Seleccione una plantilla para ingresar al panel de edición.</li>
              <li>Utilice el menú superior para editar cada sección del documento.</li>
              <li>Al finalizar la edición, presione el botón verde. Esto iniciara la descarga del documento en formato PDF.</li>
            </ol>
          </div>

          <h2>Colaboración</h2>
          <hr className="separator-red" />
          <div className="project-info text-center">
            <p className="font-22">
              CV Generator aún se encuentra en desarrollo.<br />
              ¿Te gustaría ser parte? ¡Accede al proyecto en GitHub!
            </p>
            <a
              href="https://github.com/symphonic15/react-cv-generator"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={githubImg} className="mw-100" alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}