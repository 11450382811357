import React from "react";
import { Link } from "react-router-dom";

import "./Navbar.scss";
import logo from "../../assets/img/logo-min.png";

const Navbar = () => {
  return (
    <React.Fragment>
      <nav id="navbar" className="navbar navbar-expand-lg navbar">
        <div className="container navbar-container">
          <Link className="navbar-brand navbar-logo" to="/">
            <img src={logo} alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars navbar-toggle"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ms-auto">
              <li className="navbar-item">
                <Link className="nav-link" to="/">
                  Inicio
                </Link>
              </li>
              <li className="navbar-item">
                <Link className="nav-link" to="/projects/investment-tracker">
                  Investment Tracker
                </Link>
              </li>
              <li className="navbar-item">
                <Link className="nav-link" to="/projects/mindvoice">
                  Mind Voice
                </Link>
              </li>
              <li className="navbar-item">
                <Link className="nav-link" to="/projects/cv-generator">
                  CV Generator
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="navbar-space"></div>
    </React.Fragment>
  );
}

export default Navbar;
