import { BrowserRouter, Switch, Route } from "react-router-dom";
import Layout from "./layout/Layout";
import './App.scss';

import Home from "./pages/Home";
import InvestmentTracker from "./pages/InvestmentTracker";
import MindVoice from "./pages/MindVoice";
import CVGenerator from "./pages/CVGenerator";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/projects/investment-tracker" component={InvestmentTracker} />
          <Route exact path="/projects/mindvoice" component={MindVoice} />
          <Route exact path="/projects/cv-generator" component={CVGenerator} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
